import React from "react";
import { useStaticQuery, graphql } from "gatsby";
import Container from "react-bootstrap/Container";
import CourseCard from "../common/CourseCard/CourseCard";
import Layout from "../Components/Layout";
import Section from "../common/Section/Section";

import {
  infantData,
  babyData,
  elementaryData,
  highSchoolData,
} from "../utils/content";

const links = ["コース", "子供コース"];

const ChildrenCoursePage = () => {
  const data = useStaticQuery(graphql`
    {
      prismicChildrenCourse {
        id
        last_publication_date
        data {
          baby_text {
            text
            html
          }
          baby_image {
            url
          }
          elementary_school_image {
            url
          }
          elementary_school_text {
            text
            html
          }
          high_school_image {
            url
          }
          high_school_text {
            text
            html
          }
          infant_image {
            url
          }
          infant_text {
            text
            html
          }
        }
      }
    }
  `);

  const fullInfantData = {
    ...infantData,
    image_url: data.prismicChildrenCourse.data.infant_image.url,
    text: data.prismicChildrenCourse.data.infant_text.text,
  };

  const fullBabyData = {
    ...babyData,
    image_url: data.prismicChildrenCourse.data.baby_image.url,
    text: data.prismicChildrenCourse.data.baby_text.text,
  };

  const fullElementaryData = {
    ...elementaryData,
    image_url: data.prismicChildrenCourse.data.elementary_school_image.url,
    text: data.prismicChildrenCourse.data.elementary_school_text.text,
  };

  const fullHighSchoolData = {
    ...highSchoolData,
    image_url: data.prismicChildrenCourse.data.high_school_image.url,
    text: data.prismicChildrenCourse.data.high_school_text.text,
  };

  return (
    <>
      <Layout
        links={links}
        title="Children"
        subTitle="子供コース"
        seoTitle="子供コース"
        seoDescrption="狭山スイミングクラブのお問い合わせページです。"
        seoKeywords="お問い合わせ"
      >
        <Section>
          <Container>
            <CourseCard page={fullInfantData} />
          </Container>
        </Section>

        <Section grey>
          <Container>
            <CourseCard page={fullBabyData} />
          </Container>
        </Section>

        <Section>
          <Container>
            <CourseCard page={fullElementaryData} />
          </Container>
        </Section>

        <Section grey>
          <Container>
            <CourseCard page={fullHighSchoolData} />
          </Container>
        </Section>
      </Layout>
    </>
  );
};

export default ChildrenCoursePage;
